import { ComponentType } from "@angular/cdk/portal";
import { Injectable, inject } from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { StronglyTypedMatDialog } from "../shared/directives/strongly-typed-mat-dialog";
import { InfoPopupComponent } from "../shared/components/info-popup/info-popup.component";
import { Product } from "../types/product.type";
import { ProductDownloadPopupComponent } from "../public/product/product-download-popup/product-download-popup.component";

@Injectable({
  providedIn: "root",
})
export class MatDialogService {
  protected _matDialog = inject(MatDialog);

  open<DialogData, DialogResult>(
    component: ComponentType<StronglyTypedMatDialog<DialogData, DialogResult>>,
    config?: MatDialogConfig<DialogData>,
  ): MatDialogRef<
    StronglyTypedMatDialog<DialogData, DialogResult>,
    DialogResult
  > {
    return this._matDialog.open(component, config);
  }

  createErrorPopup(text: string) {
    return this.open(InfoPopupComponent, {
      data: {
        text: text,
        buttonClass: "secondary-button",
      },
    });
  }

  createInfoPopup(text: string) {
    return this.open(InfoPopupComponent, {
      data: { text: text, buttonClass: "primary-button" },
    });
  }

  createProductDownloadPopup(product: Product, imageIndex?: number) {
    return this.open(ProductDownloadPopupComponent, {
      data: { product, imageIndex },
      width: "calc(100vw - 56px)",
      maxWidth: "800px",
      minWidth: "200px",
    });
  }
}
