import { AsyncPipe } from "@angular/common";
import {
  Component,
  computed,
  DestroyRef,
  ElementRef,
  inject,
  input,
  Signal,
  ViewChild,
} from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Router } from "@angular/router";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { combineLatest, fromEvent, map, Observable, of } from "rxjs";
import { InteractiveMapService } from "src/app/services/interactive-map.service";
import { MatDialogService } from "src/app/services/mat-dialog.service";
import { resizeObs } from "src/app/tools/rxjs.tools";
import {
  InteractiveMap,
  InteractiveMapSection,
  Position,
} from "src/app/types/interactive-map.type";

const MAP_WIDTH_REF: number = 1724;
const MAP_HEIGHT_REF: number = 743;

@Component({
  selector: "fc-interactive-map",
  standalone: true,
  imports: [AsyncPipe, MatTooltipModule],
  templateUrl: "./interactive-map.component.html",
  styleUrl: "./interactive-map.component.scss",
})
export class InteractiveMapComponent {
  private _destroyRef = inject(DestroyRef);
  private _interactiveMapService = inject(InteractiveMapService);
  private _matDialogService = inject(MatDialogService);
  private _router = inject(Router);

  id = input.required<string>();

  disabledColor: string = "#c3c3c3";

  interactiveMap: Signal<InteractiveMap | undefined> = computed(() => {
    const im = this._interactiveMapService
      .interactiveMaps()
      .find((im) => im.id === this.id());
    if (!im) {
      return undefined;
    }

    return {
      ...im,
      map: `assets/images/interactive-map/${im.map}`,
      sections: im.sections.map((s) => ({
        ...s,
        images: s.images.map((im) => ({
          ...im,
          image: `assets/images/interactive-map/${im.image}`,
        })),
        left: this.convertToPercentX(s.left),
        top: this.convertToPercentY(s.top),
        width: this.convertToPercentX(s.width),
        height: this.convertToPercentY(s.height),
        interaction: s.interaction.map((p) => ({
          x: this.convertToPercentX(p.x),
          y: this.convertToPercentY(p.y),
        })),
      })),
    };
  });

  @ViewChild("container", { static: true })
  private _container!: ElementRef<HTMLDivElement>;

  @ViewChild("map", { static: true })
  private _map!: ElementRef<HTMLDivElement>;

  activeHeaderSectionId?: string;
  cursorOn: boolean = false;

  mapWidth$: Observable<string> = of("100%");

  ngOnInit(): void {
    // Make sure map is displayed without scrolling
    this.mapWidth$ = combineLatest([
      resizeObs(this._container.nativeElement),
      resizeObs(document.documentElement),
    ]).pipe(
      map(() => {
        const mapAR = MAP_WIDTH_REF / MAP_HEIGHT_REF;
        const maxMapWidth =
          this._container.nativeElement.getBoundingClientRect().width;
        const maxMapHeight =
          document.documentElement.clientHeight -
          this._map.nativeElement.getBoundingClientRect().top;
        if (maxMapWidth / maxMapHeight > mapAR) {
          return `${maxMapHeight * mapAR}px`;
        }

        return "100%";
      }),
    );

    fromEvent<MouseEvent>(this._map.nativeElement, "mousemove")
      .pipe(
        map((e) => ({
          x: e.clientX - this._map.nativeElement.offsetLeft,
          y: e.clientY - this._map.nativeElement.offsetTop,
        })),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe((position) => {
        const point: Position = {
          x: (position.x * 100) / this._map.nativeElement.offsetWidth,
          y: (position.y * 100) / this._map.nativeElement.offsetHeight,
        };

        this.cursorOn = false;
        if (this.interactiveMap()) {
          for (let i = 0; i < this.interactiveMap()!.sections.length; i++) {
            const section = this.interactiveMap()!.sections[i];
            const inside = this.isPointInsideShape(point, section.interaction);
            if (inside) {
              if (
                section.headerId === this.activeHeaderSectionId ||
                this.activeHeaderSectionId === undefined
              ) {
                this.cursorOn = true;
              }
              break;
            }
          }
        }
      });
  }

  private convertToPercentX(value: number): number {
    return (value * 100) / MAP_WIDTH_REF;
  }

  private convertToPercentY(value: number): number {
    return (value * 100) / MAP_HEIGHT_REF;
  }

  private isPointInsideShape(
    point: Position,
    shape: ReadonlyArray<Position>,
  ): boolean {
    let inside: boolean = false;
    for (let i = 0, j = shape.length - 1; i < shape.length; j = i++) {
      const xi = shape[i].x;
      const yi = shape[i].y;
      const xj = shape[j].x;
      const yj = shape[j].y;

      const intersect =
        yi > point.y != yj > point.y &&
        point.x < ((xj - xi) * (point.y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  }

  onHeaderSectionClick(id: string): void {
    this.activeHeaderSectionId =
      this.activeHeaderSectionId === id ? undefined : id;
  }

  onSectionClick(section: InteractiveMapSection): void {
    if (section.link) {
      this._router.navigateByUrl(section.link);
    } else {
      this._matDialogService.createInfoPopup(
        "No recommended Perm solutions at this time",
      );
    }
  }
}
