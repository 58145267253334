import { computed, inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ZodError } from "zod";
import { fromError } from "zod-validation-error";
import { SettingsService } from "./settings.service";
import { UploadService } from "./upload.service";
import { extractFilename } from "../tools/file.tools";
import { environment } from "src/environments/environment";
import {
  CUSTOM_POPUP_PROPERTIES_SCHEMA,
  CustomPopup,
  CustomPopupKey,
  CustomPopupKeyNames,
  CustomPopupProperties,
} from "../types/custom-popup.type";
import { Setting } from "../types/setting.type";
import { UploadRef } from "../types/upload";

@Injectable({
  providedIn: "root",
})
export class CustomPopupService {
  private _settingsService = inject(SettingsService);
  private _uploadService = inject(UploadService);

  private _customPopupDisplayed: { [key in CustomPopupKey]: boolean } = {
    "custom-popup-1": false,
    "custom-popup-2": false,
  };

  readonly POPUP_IMAGE_FOLDER: string = "customPopups";

  popups = computed(() => {
    const settingsMap = this._settingsService.settingsKeyMap();
    const settingsPopups: {
      [key in CustomPopupKey]: CustomPopup | undefined;
    } = {
      "custom-popup-1": undefined,
      "custom-popup-2": undefined,
    };

    for (const name of CustomPopupKeyNames) {
      const s = settingsMap[name];
      if (s) {
        settingsPopups[name] = this.settingToCustomPopup(s);
      }
    }

    return settingsPopups;
  });

  private settingToCustomPopup(setting: Setting): CustomPopup | undefined {
    try {
      const settingJson = JSON.parse(setting.value);
      const properties = CUSTOM_POPUP_PROPERTIES_SCHEMA.parse(settingJson);
      return {
        id: setting.id,
        key: setting.key as CustomPopupKey,
        displayName: setting.displayName,
        properties: {
          ...properties,
          imageName: properties.imageName
            ? `${environment.container}/assets/${this.POPUP_IMAGE_FOLDER}/${properties.imageName}`
            : undefined,
        },
      };
    } catch (e) {
      if (e instanceof ZodError) {
        console.error(`Error parsing custom popup: ${fromError(e).message}`);
      } else {
        console.error(`Error parsing custom popup`);
        console.error(e);
      }
    }

    return undefined;
  }

  uploadPopupImage(file: File): UploadRef {
    return this._uploadService.uploadAsset(file, this.POPUP_IMAGE_FOLDER);
  }

  createCustomPopup(data: Omit<CustomPopup, "id">): Observable<void> {
    return this._settingsService.createSetting({
      key: data.key,
      displayName: data.displayName,
      value: JSON.stringify(this.sanitizeProperties(data.properties)),
    });
  }

  updateCustomPopup(data: CustomPopup): Observable<void> {
    return this._settingsService.updateSetting({
      id: data.id,
      displayName: data.displayName,
      value: JSON.stringify(this.sanitizeProperties(data.properties)),
    });
  }

  private sanitizeProperties(
    properties: CustomPopupProperties,
  ): CustomPopupProperties {
    return {
      ...properties,
      imageName: properties.imageName
        ? extractFilename(properties.imageName)
        : undefined,
    };
  }

  deleteCustomPopup(id: number): Observable<void> {
    return this._settingsService.deleteSetting(id);
  }

  customPopupAlreadyDisplayed(key: CustomPopupKey): boolean {
    return this._customPopupDisplayed[key];
  }

  setCustomPopupAlreadyDisplayed(key: CustomPopupKey): void {
    this._customPopupDisplayed[key] = true;
  }

  customPopupAlreadyInLocalStorage(key: CustomPopupKey): boolean {
    return window.localStorage.getItem(key) === "true";
  }

  setCustomPopupInLocalStorage(key: CustomPopupKey): void {
    window.localStorage.setItem(key, "true");
  }

  removeCustomPopupInLocalStorage(key: CustomPopupKey): void {
    window.localStorage.removeItem(key);
  }
}
