import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  inject,
  effect,
  computed,
  Signal,
} from "@angular/core";
import { NgClass } from "@angular/common";
import { RouterLink } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { SharedService } from "src/app/shared/services/shared.service";
import { EventBrokerService } from "src/app/shared/services/eventBroker.service";
import { AuthService } from "src/app/public/auth/auth.service";
import { CategoriesService } from "src/app/services/categories.service";
import { AppState } from "src/app/store/state/app.state";
import {
  DisplayStyle,
  ISiteSettings,
} from "src/app/store/models/ISiteSettings";
import { ClientFunctions } from "src/app/shared/interfaces/client-functions";
import { setSiteSettingsDisplayStyleAction } from "src/app/store/actions/SiteSettings.actions";
import { environment } from "src/environments/environment";
import { InteractiveMapComponent } from "src/app/public/interactive-map/interactive-map.component";
import { InteractiveMapService } from "src/app/services/interactive-map.service";
import { Category } from "src/app/types/category.type";

declare var dropdownHoverIntent: any;
declare var $: any;

type MenuCategory = Omit<Category, "children"> & {
  link: string;
  children: MenuCategory[];
};

@Component({
  selector: "fc-menu-desktop",
  templateUrl: "./menu.desktop.component.html",
  styleUrls: ["./menu.desktop.component.scss"],
  standalone: true,
  imports: [NgClass, RouterLink],
})
export class MenuDesktopComponent implements OnInit, OnDestroy {
  @Input() isFsvSecureAccess?: boolean;

  private _categoriesService = inject(CategoriesService);
  private _interactiveMapService = inject(InteractiveMapService);

  categories: Signal<MenuCategory[]> = computed(() => {
    if (environment.environmentId === "pbna") {
      const interactiveMaps = this._interactiveMapService.interactiveMaps();
      return this._categoriesService
        .categories()
        .map<MenuCategory>((l1Category) => {
          const l2Categories = l1Category.children.map<MenuCategory>(
            (l2Category) => {
              const im = interactiveMaps.find(
                (im) => im.categoryId === l2Category.id,
              );
              const link = `/products/categories/${l2Category.id}`;
              const l3Categories = l2Category.children.map<MenuCategory>(
                (l3Category) => ({
                  ...l3Category,
                  link: `/products/categories/${l3Category.id}`,
                  children: [],
                }),
              );

              if (im) {
                return {
                  ...l2Category,
                  link,
                  children: [
                    {
                      id: -2,
                      name: "Interactive Map",
                      displayOrder: 0,
                      children: [],
                      link: `interactive-map/${im.id}`,
                    },
                    ...l3Categories,
                  ],
                };
              }

              return { ...l2Category, link, children: l3Categories };
            },
          );

          const link = `/products/categories/${l1Category.id}`;

          const im = interactiveMaps.find(
            (im) => im.categoryId === l1Category.id,
          );
          if (im) {
            return {
              ...l1Category,
              link,
              children: [
                {
                  id: -2,
                  name: "Interactive Map",
                  displayOrder: 0,
                  children: [],
                  link: `interactive-map/${im.id}`,
                },
                ...l2Categories,
              ],
            };
          }

          return { ...l1Category, link, children: l2Categories };
        });
    } else {
      return this._categoriesService
        .categories()
        .map<MenuCategory>((l1Category) => {
          return {
            ...l1Category,
            link: `/products/categories/${l1Category.id}`,
            children: l1Category.children.map<MenuCategory>((l2Category) => ({
              ...l2Category,
              link: `/products/categories/${l2Category.id}`,
              children: l2Category.children.map<MenuCategory>((l3Category) => ({
                ...l3Category,
                link: `/products/categories/${l3Category.id}`,
                children: [],
              })),
            })),
          };
        });
    }
  });

  private desktopMenuSubjectSubscription?: Subscription;
  private displayStyle: DisplayStyle = DisplayStyle.Cards;
  public isCardLayout = true;

  clientFunctions: ClientFunctions = {
    mcoe: {},
    fsv: {},
    pbna: {},
  };

  private _dropdownCreated: boolean = false;

  constructor(
    private store: Store<AppState>,
    public sharedService: SharedService,
    private eventBroker: EventBrokerService,
    public authService: AuthService,
  ) {
    this.store
      .select((state) => state.siteSettings)
      .subscribe((s: ISiteSettings) => {
        this.displayStyle = s ? s.displayStyle : DisplayStyle.Cards;
        this.isCardLayout = this.displayStyle === DisplayStyle.Cards;
      });

    effect(() => {
      if (!this._dropdownCreated && this.categories().length > 0) {
        this._dropdownCreated = true;
        new dropdownHoverIntent();
      }
    });
  }

  ngOnInit() {
    this.clientFunctions = {
      mcoe: {
        inventoryClicked: () => {
          this.displayInventoryModal();
        },
        epopClicked: () => {
          window.open("https://mcoe.mypepsico.com/", "_blank");
        },
      },
      fsv: {
        epopClicked: () => {
          window.open(
            "https://epop.imsfastpak.com/default.aspx?ReturnUrl=%2fProductSearch.aspx",
            "_blank",
          );
        },
      },
      pbna: {
        inventoryClicked: () => {},
        epopClicked: () => {
          window.open("https://epop.imsfastpak.com/default.aspx", "_blank");
        },
      },
    };
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.sharedService.environmentIsFSV()) {
      this.desktopMenuSubjectSubscription?.unsubscribe();
    }
  }

  navigateToCategoryId(categoryId?: number) {
    this.sharedService.navigateToCategoryId(categoryId ?? -1);
  }

  navigateToBugName(bugName: string) {
    this.sharedService.navigateToBugName(bugName);
  }

  displayInventoryModal() {
    $("#black-overlay").addClass("visible");
    $("#inventory-modal").modal("show");
  }

  switchDisplayStyle() {
    this.store.dispatch(
      setSiteSettingsDisplayStyleAction({
        displayStyle:
          this.displayStyle === DisplayStyle.Cards
            ? DisplayStyle.Rows
            : DisplayStyle.Cards,
      }),
    );

    setTimeout(() => {
      // Re-emit "productsLoaded" as it will re-compute the total height
      this.eventBroker.emit<void>("productsLoaded", undefined);
    }, 500);
  }

  openSellSheets() {
    window.open(
      "https://pepsico.sharepoint.com/:f:/r/sites/FLNAAFHDistributors/Shared%20Documents/Merchandising/Frito%20Lay/FLNA%20Equipment%20Catalog/Equipment%20Sell%20Pages?csf=1&web=1&e=mbsIui",
      "_blank",
    );
  }
}
