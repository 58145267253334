<div #container class="container">
  @if (interactiveMap()) {
    <h1>{{ interactiveMap()?.name }}</h1>

    <div class="menu">
      @for (sectionHeader of interactiveMap()?.sectionHeaders; track $index) {
        <button
          class="menu-section"
          [style.backgroundColor]="
            activeHeaderSectionId === sectionHeader.id ||
            activeHeaderSectionId === undefined
              ? sectionHeader.color
              : disabledColor
          "
          (click)="onHeaderSectionClick(sectionHeader.id)"
        >
          {{ sectionHeader.text }}
        </button>
      }
    </div>

    <div class="instructions">
      Click on a highlighted section to access displays for that store location
    </div>
  }

  <div
    #map
    class="map-container"
    [style.cursor]="cursorOn ? 'pointer' : 'initial'"
    [style.width]="mapWidth$ | async"
  >
    @if (interactiveMap()) {
      <img class="background" [src]="interactiveMap()!.map" />

      @for (section of interactiveMap()!.sections; track $index) {
        @for (im of section.images; track $index) {
          @if (
            activeHeaderSectionId === section.headerId ||
            activeHeaderSectionId === undefined
          ) {
            <img
              class="map-section"
              [matTooltip]="section.info"
              matTooltipPosition="above"
              [style.left]="section.left + '%'"
              [style.top]="section.top + '%'"
              [style.width]="section.width + '%'"
              [style.height]="section.height + '%'"
              [style.mixBlendMode]="im.mixBlend"
              [src]="im.image"
              (click)="onSectionClick(section)"
            />
          }
        }
      }
    }
  </div>
</div>
