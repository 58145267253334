import { environment } from "src/environments/environment";
import { Product as ProductGQL } from "../types/graphql/graphql.type";
import {
  Product,
  PRODUCT_SELLSHEET_SCHEMA,
  ProductCategory,
  ProductEditSearch,
  ProductEditSearchCategory,
  ProductImage,
  ProductProperties,
  ProductSellsheet,
} from "../types/product.type";
import { z, ZodError } from "zod";
import { fromError } from "zod-validation-error";
import { extractFileBasename } from "./file.tools";

type ProductPropertiesDB = {
  // All flavors
  readonly images?: ReadonlyArray<string>;
  readonly videos?: ReadonlyArray<string>;
  readonly hasSellSheet?: boolean;

  // MCOE & FSV
  readonly enableShareableLink?: boolean;
  readonly has360View?: boolean;
  readonly hasVideo?: boolean;

  // PBNA
  readonly dimensions?: string;
  readonly unitsPerCarton?: string;
  readonly supplier?: string;
  readonly accountProgram?: string;
  readonly imagesCount?: number;
  readonly hasOnePager?: boolean;
  readonly brand?: string;
  readonly estPricing?: string;
  readonly supplierOnePager?: string;
  readonly imageOnePager?: string;
  readonly mustWin?: string;
  readonly greatLocation?: string;
  readonly ePop?: string;
  readonly price?: string;
  readonly sellSheetPdf?: string;
};

const PRODUCT_PROPERTIES_DB_SCHEMA = z.object({
  images: z.array(z.string()).optional(),
  videos: z.array(z.string()).optional(),
  hasSellSheet: z.boolean().optional(),

  enableShareableLink: z.boolean().optional(),
  has360View: z.boolean().optional(),
  hasVideo: z.boolean().optional(),

  dimensions: z.string().optional(),
  unitsPerCarton: z.string().optional(),
  supplier: z.string().optional(),
  accountProgram: z.string().optional(),
  imagesCount: z.number().optional(),
  hasOnePager: z.boolean().optional(),
  brand: z.string().optional(),
  estPricing: z.string().optional(),
  supplierOnePager: z.string().optional(),
  imageOnePager: z.string().optional(),
  mustWin: z.string().optional(),
  greatLocation: z.string().optional(),
  ePop: z.string().optional(),
  price: z.string().optional(),
  sellSheetPdf: z.string().optional(),
});

export function productGQLtoProductEditSearch(
  product: ProductGQL,
): ProductEditSearch {
  return {
    id: product.id,
    name: product.name,
    eqi: product.eqi,
    thumbnail: product.image
      ? parseProductMainThumbnail(product.image)
      : undefined,
    categories: parseProductEditSearchCategories(product),
    bugs: product.bugs.map((b) => b.id),
    colors: product.productColors.map((pc) => pc.color.id),
  };
}

function parseProductMainThumbnail(image: string): string {
  let baseImageName: string = image;
  const extInd = image.lastIndexOf(".");
  if (extInd >= 0) {
    baseImageName = image.slice(0, extInd);
  }
  return `${environment.container}/images-products/thumbs/${baseImageName}x300.jpg`;
}

function parseProductEditSearchCategories(
  product: ProductGQL,
): ProductEditSearchCategory[] {
  return product.categories.map((c) => {
    if (c.parent?.parent) {
      return {
        l1: { id: c.parent.parent.id, name: c.parent.parent.name },
        l2: { id: c.parent.id, name: c.parent.name },
        l3: { id: c.id, name: c.name },
      };
    } else if (c.parent) {
      return {
        l1: { id: c.parent.id, name: c.parent.name },
        l2: { id: c.id, name: c.name },
      };
    }
    return {
      l1: { id: c.id, name: c.name },
    };
  });
}

export function productGQLtoProduct(product: ProductGQL): Product {
  const propertiesDB = parseProductPropertiesDB(product) ?? {};
  return {
    id: product.id,
    name: product.name,
    sortId: product.sortId,
    eqi: product.eqi,
    uom: product.uom ?? undefined,
    depth: product.depth ?? undefined,
    width: product.width ?? undefined,
    height: product.height ?? undefined,
    size: product.size ?? undefined,
    metadata:
      product.metadata && product.metadata.length > 0
        ? product.metadata.split(",")
        : [],
    images: parseProductImages(product, propertiesDB),
    notes: product.notes ?? undefined,
    properties: productPropertiesDBtoProductProperties(product, propertiesDB),
    hasSellSheet: product.hasSellSheet,
    sellsheet: parseProductSellsheet(product),
    colors: product.productColors.map((c) => ({
      id: c.color.id,
      fullEqi: c.fullEqi ?? undefined,
    })),
    bugs: product.bugs.map((b) => b.id),
    categories: parseProductCategories(product),
  };
}

function parseProductPropertiesDB(
  product: ProductGQL,
): ProductPropertiesDB | undefined {
  let properties: ProductPropertiesDB | undefined;
  try {
    const json = JSON.parse(product.properties || "{}");
    properties = PRODUCT_PROPERTIES_DB_SCHEMA.parse(json);
  } catch (e) {
    if (e instanceof ZodError) {
      console.error(
        `Error parsing properties for product ${product.id}: ${fromError(e).message}`,
      );
    } else {
      console.error(`Error parsing properties for product ${product.id}`);
      console.error(e);
    }
  }

  return properties;
}

function productPropertiesDBtoProductProperties(
  product: ProductGQL,
  properties: ProductPropertiesDB,
): ProductProperties {
  return {
    videos: parseProductVideos(product, properties),
    hasSellSheet: properties.hasSellSheet,

    enableShareableLink: properties.enableShareableLink,
    has360View: properties.has360View,

    dimensions: properties.dimensions,
    unitsPerCarton: properties.unitsPerCarton,
    supplier: properties.supplier,
    accountProgram: properties.accountProgram,
    hasOnePager: properties.hasOnePager,
    brand: properties.brand,
    estPricing: properties.estPricing,
    supplierOnePager: properties.supplierOnePager,
    imageOnePager: properties.imageOnePager,
    mustWin: properties.mustWin,
    greatLocation: properties.greatLocation,
    ePop: properties.ePop,
    price: properties.price,
    sellSheetPdf: properties.sellSheetPdf
      ? `${environment.container}/assets/pdfs/${properties.sellSheetPdf}`
      : undefined,
  };
}

function parseProductImages(
  product: ProductGQL,
  properties: ProductPropertiesDB,
): ProductImage[] {
  const images: ProductImage[] = [];
  if (environment.environmentId === "pbna") {
    if (product.image) {
      const imageBaseName = extractFileBasename(product.image);
      images.push({
        image: `${environment.container}/images-products/jpeg/${imageBaseName}.jpg`,
        smallThumb: `${environment.container}/images-products/thumbs/${imageBaseName}x300.jpg`,
        largeThumb: `${environment.container}/images-products/thumbs/${imageBaseName}x600.jpg`,
      });
    }

    if (properties.images) {
      properties.images.map((image) => {
        const imageBaseName = extractFileBasename(image);
        images.push({
          image: `${environment.container}/images-products/jpeg/${imageBaseName}.jpg`,
          smallThumb: `${environment.container}/images-products/thumbs/${imageBaseName}x300.jpg`,
          largeThumb: `${environment.container}/images-products/thumbs/${imageBaseName}x600.jpg`,
        });
      });
    } else if (properties?.imagesCount) {
      for (let i = 1; i < properties.imagesCount; i++) {
        const ind = i + 1;
        images.push({
          image: `${environment.container}/images-products/jpeg/${product.eqi}-${ind}.jpg`,
          smallThumb: `${environment.container}/images-products/thumbs/${product.eqi}-${ind}x300.jpg`,
          largeThumb: `${environment.container}/images-products/thumbs/${product.eqi}-${ind}x600.jpg`,
        });
      }
    }
  } else {
    const imageBaseName: string =
      product.productColors[0]?.fullEqi ?? product.eqi;
    images.push({
      image: `${environment.container}/images-products/jpeg/${imageBaseName}.jpg`,
      smallThumb: `${environment.container}/images-products/thumbs/${imageBaseName}x300.jpg`,
      largeThumb: `${environment.container}/images-products/thumbs/${imageBaseName}x600.jpg`,
    });
  }
  return images;
}

function parseProductVideos(
  product: ProductGQL,
  properties?: ProductPropertiesDB,
): string[] {
  if (properties?.videos) {
    return properties.videos.map(
      (v) => `${environment.container}/images-products/videos/${v}`,
    );
  } else if (properties?.hasVideo === true) {
    return [
      `${environment.container}/images-products/videos/${product.eqi}.mp4`,
    ];
  }

  return [];
}

function parseProductSellsheet(
  product: ProductGQL,
): ProductSellsheet | undefined {
  let sellsheet: ProductSellsheet | undefined;
  try {
    const displaySpecJson = JSON.parse(product.sellSheet?.displaySpec || "{}");
    const economicsJson = JSON.parse(product.sellSheet?.economics || "{}");
    sellsheet = PRODUCT_SELLSHEET_SCHEMA.parse({
      displaySpec: displaySpecJson,
      economics: economicsJson,
    });
  } catch (e) {
    if (e instanceof ZodError) {
      console.error(
        `Error parsing sellsheet for product ${product.id}: ${fromError(e).message}`,
      );
    } else {
      console.error(`Error parsing sellsheet for product ${product.id}`);
      console.error(e);
    }
  }

  return sellsheet;
}

function parseProductCategories(product: ProductGQL): ProductCategory[] {
  return product.categories.map((c) => {
    if (c.parent?.parent) {
      return {
        l1: c.parent.parent.id,
        l2: c.parent.id,
        l3: c.id,
      };
    } else if (c.parent) {
      return {
        l1: c.parent.id,
        l2: c.id,
      };
    }
    return {
      l1: c.id,
    };
  });
}
