@if (!display360) {
  <h2 mat-dialog-title>{{ product?.name }}</h2>
}

<mat-dialog-content #matDialogContent id="content" class="mat-typography">
  @if (sharedService.environmentIsMCOE()) {
    @if (!display360) {
      @if (currentProductImage) {
        <lib-ngx-image-zoom
          #libNgxImageZoom
          [thumbImage]="currentProductImage.image"
          [zoomMode]="isMobile ? 'toggle' : 'click'"
          [magnification]="magnification"
          [ngClass]="{
            'lib-ngx-image-zoom-scale': has360View,
            'lib-ngx-image-zoom-classic': !has360View,
          }"
        >
        </lib-ngx-image-zoom>
      }
    } @else {
      @if (product) {
        <gc-fritolay-product-viewer
          [eqi]="product.eqi"
          [productName]="product.name"
          [productWidth]="product.width ?? null"
          [productHeight]="product.height ?? null"
          [productDepth]="product.depth ?? null"
          [showCloseButton]="false"
        >
        </gc-fritolay-product-viewer>
      }
    }
  } @else {
    @if (currentProductImage) {
      <img [src]="currentProductImage.image" (load)="imageLoaded()" />
      @if (loadingImage) {
        <mat-spinner [diameter]="50"></mat-spinner>
      }
    } @else {
      <img src="assets/images/common/no_image.svg" (load)="imageLoaded()" />
    }
  }
</mat-dialog-content>

<mat-dialog-actions>
  <div class="actions-container">
    <div class="side left">
      @if (product && product.images.length > 0) {
        <button mat-flat-button type="button" (click)="downloadImages()">
          Download
          <mat-icon iconPositionEnd>download</mat-icon>
        </button>
      }

      @if (sharedService.environmentIsPBNA() && product) {
        @if (
          product.properties.videos && product.properties.videos.length > 0
        ) {
          <button mat-flat-button type="button" (click)="playVideo()">
            Video
            <mat-icon iconPositionEnd>play_arrow</mat-icon>
          </button>
        }

        @if (ePopValidUrl) {
          <a
            mat-flat-button
            type="button"
            [href]="product.properties.ePop"
            target="_blank"
          >
            Learn More
          </a>
        }

        @if (
          product.properties.sellSheetPdf &&
          product.properties.sellSheetPdf.length > 0
        ) {
          <a
            mat-flat-button
            [href]="product.properties.sellSheetPdf"
            target="_blank"
          >
            Sell Sheet
          </a>
        }
      }
    </div>
    <div class="side right">
      @if (sharedService.environmentIsMCOE()) {
        @if (showButton360) {
          <button
            id="button-360"
            type="button"
            class="mcoe-actions-button ga-clic-nsm-2021-360-button"
            [ngClass]="{ active: display360 }"
            [attr.data-titlega]="product?.eqi + ' - ' + product?.name"
            (click)="toggle360()"
          ></button>
        }

        @if (showVideoButton) {
          <div class="button-container">
            <button
              id="button-video"
              type="button"
              class="mcoe-actions-button ga-clic-nsm-2021-video-button"
              [attr.data-titlega]="product?.eqi + ' - ' + product?.name"
              [disabled]="downloadingVideo"
              (click)="downloadVideo()"
            ></button>
            @if (downloadingVideo) {
              <mat-spinner [diameter]="20"></mat-spinner>
            }
          </div>
        }

        @if (showButtonLink) {
          <button
            id="button-link"
            type="button"
            class="mcoe-actions-button ga-clic-nsm-2021-link-button"
            [attr.data-titlega]="product?.eqi + ' - ' + product?.name"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            [cdkCopyToClipboard]="productViewerLink"
            (click)="displayButtonLinkTooltip()"
          ></button>
          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayPositions]="buttonLinkTooltipPositions"
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="buttonLinkTooltipOn"
          >
            <div class="click-tooltip">Copied to clipboard!</div>
          </ng-template>
        }
      }
      @if (product && sharedService.environmentIsPBNA()) {
        <div class="thumbnails-container">
          @for (productImage of product.images; track $index) {
            <button
              class="thumbnail-item"
              (click)="changeProductImage(productImage)"
            >
              <img [src]="productImage.smallThumb" />
            </button>
          }
        </div>
      }
      <button mat-flat-button matDialogClose type="button">Close</button>
    </div>
  </div>
</mat-dialog-actions>
