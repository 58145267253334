import { computed, inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { z, ZodError } from "zod";
import { SettingsService } from "./settings.service";
import { InteractiveMap } from "../types/interactive-map.type";
import { Setting } from "../types/setting.type";
import { fromError } from "zod-validation-error";

const INTERACTIVE_MAP_SETTING_VALUE_SCHEMA = z.object({
  id: z.string(),
  category: z.number().optional(),
  sections: z.array(
    z.object({
      id: z.string(),
      link: z.string(),
    }),
  ),
});

@Injectable({
  providedIn: "root",
})
export class InteractiveMapService {
  private _settingsService = inject(SettingsService);

  readonly SETTING_KEY_PREFIX: string = "interactive-map";

  interactiveMaps = computed(() => {
    const settings = this._settingsService
      .settings()
      .filter((s) => s.key.startsWith(this.SETTING_KEY_PREFIX));

    const interactiveMaps: InteractiveMap[] = [];
    for (const setting of settings) {
      const im = this.settingToInteractiveMap(setting);
      if (im) {
        interactiveMaps.push(im);
      }
    }

    return interactiveMaps;
  });

  private settingToInteractiveMap(
    setting: Setting,
  ): InteractiveMap | undefined {
    try {
      const settingJson = JSON.parse(setting.value);
      const properties =
        INTERACTIVE_MAP_SETTING_VALUE_SCHEMA.parse(settingJson);

      const config = INTERACTIVE_MAPS.find((im) => im.id === properties.id);
      if (config) {
        return {
          ...config,
          settingId: setting.id,
          name: setting.displayName,
          categoryId: properties.category,
          sections: config.sections.map((r) => ({
            ...r,
            link: properties.sections.find((l) => l.id === r.id)?.link,
          })),
        };
      }

      console.warn(
        `Config for interactive map with ID ${properties.id} not found`,
      );
    } catch (e) {
      if (e instanceof ZodError) {
        console.error(`Error parsing custom popup: ${fromError(e).message}`);
      } else {
        console.error(`Error parsing custom popup`);
        console.error(e);
      }
    }

    return undefined;
  }

  updateInteractiveMap(data: InteractiveMap): Observable<void> {
    const jsonValue: z.infer<typeof INTERACTIVE_MAP_SETTING_VALUE_SCHEMA> = {
      id: data.id,
      ...(data.categoryId !== undefined && { category: data.categoryId }),
      sections: data.sections
        .filter((s) => s.link && s.link?.length > 0)
        .map((s) => ({ id: s.id, link: s.link! })),
    };
    return this._settingsService.updateSetting({
      id: data.settingId,
      value: JSON.stringify(jsonValue),
    });
  }
}

const INTERACTIVE_MAPS: Omit<
  InteractiveMap,
  "categoryId" | "settingId" | "name"
>[] = [
  {
    id: "grocery-channel",
    map: "grocery-channel/grocery_channel_map.jpg",
    sectionHeaders: [
      { id: "center-store", text: "Center Store", color: "#94b0d4" },
      { id: "perimeter", text: "Perimeter", color: "#e7c9b1" },
      { id: "lobby", text: "Lobby", color: "#c3bacd" },
      { id: "cold", text: "Cold", color: "#cbd5b7" },
      { id: "impulse", text: "Impulse", color: "#dfdbad" },
      { id: "outside", text: "Outside", color: "#b3d0d8" },
    ],
    sections: [
      {
        id: "click-collect",
        name: "Click & Collect",
        headerId: "impulse",
        images: [
          {
            image: "grocery-channel/grocery_channel_click_collect_overlay.png",
            mixBlend: "multiply",
          },
          {
            image: "grocery-channel/grocery_channel_click_collect_text.png",
            mixBlend: "normal",
          },
        ],
        left: 69,
        top: 344,
        width: 201,
        height: 279,
        interaction: [
          { x: 70, y: 503 },
          { x: 179, y: 345 },
          { x: 200, y: 345 },
          { x: 217, y: 453 },
          { x: 270, y: 453 },
          { x: 180, y: 622 },
          { x: 93, y: 622 },
        ],
        info: "Beverage spend increased +6% when assortment extends beyond core, add innovation here",
      },
      {
        id: "liquor",
        name: "Liquor",
        headerId: "perimeter",
        images: [
          {
            image: "grocery-channel/grocery_channel_liquor_overlay.png",
            mixBlend: "multiply",
          },
          {
            image: "grocery-channel/grocery_channel_liquor_text.png",
            mixBlend: "normal",
          },
        ],
        left: 227,
        top: 233,
        width: 91,
        height: 218,
        interaction: [
          { x: 227, y: 334 },
          { x: 297, y: 235 },
          { x: 312, y: 341 },
          { x: 252, y: 449 },
        ],
        info: "CSDs are 27% more likely to be consume while “socializing” and 40% more likely to be consumed while “partying”- making them perfect to display near beer/wine for party needs!",
      },
      {
        id: "frozen-chilled",
        name: "Frozen/Chilled",
        headerId: "perimeter",
        images: [
          {
            image: "grocery-channel/grocery_channel_frozen_chilled_overlay.png",
            mixBlend: "multiply",
          },
          {
            image: "grocery-channel/grocery_channel_frozen_chilled_text.png",
            mixBlend: "normal",
          },
        ],
        left: 298,
        top: 89,
        width: 113,
        height: 253,
        interaction: [
          { x: 298, y: 231 },
          { x: 386, y: 90 },
          { x: 394, y: 192 },
          { x: 312, y: 340 },
        ],
        info: "57% Lipton Iced Tea & 52% of Pure Leaf Baskets include a Frozen Item",
      },
      {
        id: "dairy",
        name: "Dairy",
        headerId: "perimeter",
        images: [
          {
            image: "grocery-channel/grocery_channel_dairy_overlay.png",
            mixBlend: "multiply",
          },
          {
            image: "grocery-channel/grocery_channel_dairy_text.png",
            mixBlend: "normal",
          },
        ],
        left: 387,
        top: 76,
        width: 317,
        height: 120,
        interaction: [
          { x: 387, y: 89 },
          { x: 703, y: 89 },
          { x: 703, y: 191 },
          { x: 387, y: 191 },
        ],
        info: "Gatorade baskets are 25% more likely to include a dairy item then a typical basket",
      },
      {
        id: "meat",
        name: "Meat",
        headerId: "perimeter",
        images: [
          {
            image: "grocery-channel/grocery_channel_meat_overlay.png",
            mixBlend: "multiply",
          },
          {
            image: "grocery-channel/grocery_channel_meat_text.png",
            mixBlend: "normal",
          },
        ],
        left: 704,
        top: 76,
        width: 245,
        height: 120,
        interaction: [
          { x: 705, y: 89 },
          { x: 948, y: 89 },
          { x: 948, y: 191 },
          { x: 705, y: 191 },
        ],
        info: "Energy baskets are 29% more likely to include meat than a typical basket",
      },
      {
        id: "bakery",
        name: "Bakery",
        headerId: "perimeter",
        images: [
          {
            image: "grocery-channel/grocery_channel_bakery_overlay.png",
            mixBlend: "multiply",
          },
          {
            image: "grocery-channel/grocery_channel_bakery_text.png",
            mixBlend: "normal",
          },
        ],
        left: 979,
        top: 76,
        width: 253,
        height: 120,
        interaction: [
          { x: 979, y: 89 },
          { x: 1232, y: 89 },
          { x: 1222, y: 191 },
          { x: 979, y: 191 },
        ],
        info: "41% of Starbucks baskets include a bakery item",
      },
      {
        id: "produce",
        name: "Produce",
        headerId: "perimeter",
        images: [
          {
            image: "grocery-channel/grocery_channel_produce_overlay.png",
            mixBlend: "multiply",
          },
          {
            image: "grocery-channel/grocery_channel_produce_text.png",
            mixBlend: "normal",
          },
        ],
        left: 1034,
        top: 105,
        width: 300,
        height: 319,
        interaction: [
          { x: 1030, y: 192 },
          { x: 1222, y: 193 },
          { x: 1233, y: 106 },
          { x: 1335, y: 329 },
          { x: 1312, y: 423 },
          { x: 1074, y: 423 },
        ],
        info: "71% of consumers always pair sparkling water with food; 66% of consumers think it pairs better with lighter and healthier food",
      },
      {
        id: "outside",
        name: "Outside",
        headerId: "outside",
        images: [
          {
            image: "grocery-channel/grocery_channel_outside_overlay.png",
            mixBlend: "multiply",
          },
          {
            image: "grocery-channel/grocery_channel_outside_text.png",
            mixBlend: "normal",
          },
        ],
        left: 1339,
        top: 175,
        width: 356,
        height: 465,
        interaction: [
          { x: 1339, y: 176 },
          { x: 1406, y: 176 },
          { x: 1694, y: 639 },
          { x: 1461, y: 639 },
          { x: 1488, y: 457 },
        ],
      },
      {
        id: "lobby",
        name: "Lobby",
        headerId: "lobby",
        images: [
          {
            image: "grocery-channel/grocery_channel_lobby_overlay.png",
            mixBlend: "multiply",
          },
          {
            image: "grocery-channel/grocery_channel_lobby_text.png",
            mixBlend: "normal",
          },
        ],
        left: 1060,
        top: 424,
        width: 398,
        height: 220,
        interaction: [
          { x: 1062, y: 425 },
          { x: 1313, y: 424 },
          { x: 1379, y: 595 },
          { x: 1439, y: 599 },
          { x: 1458, y: 643 },
          { x: 1096, y: 643 },
        ],
        info: "Lobby Displays #1 Impact on any incremental beverage purchase",
      },
      {
        id: "checkout",
        name: "Checkout",
        headerId: "impulse",
        images: [
          {
            image: "grocery-channel/grocery_channel_checkout_overlay.png",
            mixBlend: "multiply",
          },
          {
            image: "grocery-channel/grocery_channel_checkout_text.png",
            mixBlend: "normal",
          },
        ],
        left: 298,
        top: 449,
        width: 590,
        height: 175,
        interaction: [
          { x: 355, y: 483 },
          { x: 887, y: 483 },
          { x: 887, y: 623 },
          { x: 299, y: 623 },
        ],
        info: "64% of shoppers notice beverages at check out",
      },
      {
        id: "front-end-aisle",
        name: "Front end Aisle",
        headerId: "perimeter",
        images: [
          {
            image:
              "grocery-channel/grocery_channel_front_end_aisle_overlay.png",
            mixBlend: "multiply",
          },
          {
            image: "grocery-channel/grocery_channel_front_end_aisle_text.png",
            mixBlend: "normal",
          },
        ],
        left: 266,
        top: 391,
        width: 622,
        height: 98,
        interaction: [
          { x: 307, y: 403 },
          { x: 885, y: 403 },
          { x: 885, y: 483 },
          { x: 267, y: 483 },
        ],
        info: "Beverage is the largest category in the Front End with 54% share",
      },
      {
        id: "end-cap",
        name: "End Cap 1",
        headerId: "perimeter",
        images: [
          {
            image: "grocery-channel/grocery_channel_end_cap_1_overlay.png",
            mixBlend: "multiply",
          },
          {
            image: "grocery-channel/grocery_channel_end_cap_1_text.png",
            mixBlend: "normal",
          },
        ],
        left: 404,
        top: 340,
        width: 246,
        height: 66,
        interaction: [
          { x: 406, y: 343 },
          { x: 645, y: 346 },
          { x: 648, y: 403 },
          { x: 411, y: 400 },
        ],
        info: "Across Total LRB, average incremental $/store/end cap display/ week is $125 (display only) OR $449 (feature and display)",
      },
      {
        id: "end-cap",
        name: "End Cap 2",
        headerId: "perimeter",
        images: [
          {
            image: "grocery-channel/grocery_channel_end_cap_2_overlay.png",
            mixBlend: "multiply",
          },
        ],
        left: 452,
        top: 220,
        width: 214,
        height: 62,
        interaction: [
          { x: 453, y: 221 },
          { x: 663, y: 221 },
          { x: 663, y: 241 },
          { x: 453, y: 241 },
        ],
        info: "Across Total LRB, average incremental $/store/end cap display/ week is $125 (display only) OR $449 (feature and display)",
      },
      {
        id: "side-wing",
        name: "Side Wing 1",
        headerId: "perimeter",
        images: [
          {
            image: "grocery-channel/grocery_channel_side_wing_1_overlay.png",
            mixBlend: "multiply",
          },
          {
            image: "grocery-channel/grocery_channel_side_wing_1_text.png",
            mixBlend: "normal",
          },
        ],
        left: 669,
        top: 344,
        width: 143,
        height: 63,
        interaction: [
          { x: 672, y: 346 },
          { x: 810, y: 346 },
          { x: 810, y: 405 },
          { x: 672, y: 405 },
        ],
        info: "Small Footprint displays that can be stacked or hung on End Cap Wing",
      },
      {
        id: "side-wing",
        name: "Side Wing 2",
        headerId: "perimeter",
        images: [
          {
            image: "grocery-channel/grocery_channel_side_wing_2_overlay.png",
            mixBlend: "multiply",
          },
        ],
        left: 683,
        top: 222,
        width: 134,
        height: 50,
        interaction: [
          { x: 684, y: 222 },
          { x: 816, y: 222 },
          { x: 816, y: 240 },
          { x: 684, y: 240 },
        ],
        info: "Small Footprint displays that can be stacked or hung on End Cap Wing",
      },
      {
        id: "gondola",
        name: "Gondola",
        headerId: "center-store",
        images: [
          {
            image:
              "grocery-channel/grocery_channel_gondola_in_aisle_overlay.png",
            mixBlend: "multiply",
          },
          {
            image: "grocery-channel/grocery_channel_gondola_in_aisle_text.png",
            mixBlend: "normal",
          },
        ],
        left: 367,
        top: 240,
        width: 416,
        height: 125,
        interaction: [
          { x: 424, y: 241 },
          { x: 781, y: 241 },
          { x: 761, y: 342 },
          { x: 381, y: 342 },
        ],
        info: "Gondola sections closest to aisle entries are prime positions",
      },
      {
        id: "perimeter",
        name: "Perimeter",
        headerId: "perimeter",
        images: [
          {
            image:
              "grocery-channel/grocery_channel_perimeter_in_aisle_overlay.png",
            mixBlend: "multiply",
          },
          {
            image:
              "grocery-channel/grocery_channel_perimeter_in_aisle_text.png",
            mixBlend: "normal",
          },
        ],
        left: 813,
        top: 243,
        width: 71,
        height: 123,
        interaction: [
          { x: 821, y: 243 },
          { x: 884, y: 243 },
          { x: 884, y: 365 },
          { x: 821, y: 365 },
        ],
        info: "Penetrate the perimeter- over 90 display opportunities per store per week",
      },
      {
        id: "deli",
        name: "Deli",
        headerId: "perimeter",
        images: [
          {
            image: "grocery-channel/grocery_channel_deli_overlay.png",
            mixBlend: "multiply",
          },
          {
            image: "grocery-channel/grocery_channel_deli_text.png",
            mixBlend: "normal",
          },
        ],
        left: 886,
        top: 192,
        width: 188,
        height: 251,
        interaction: [
          { x: 887, y: 193 },
          { x: 1033, y: 193 },
          { x: 1072, y: 423 },
          { x: 1060, y: 423 },
          { x: 1062, y: 440 },
          { x: 888, y: 440 },
        ],
        info: "Pepsi baskets are 44% more likely to include Deli Meat than a Typical Basket",
      },
      {
        id: "cooler-banks",
        name: "Cooler Banks",
        headerId: "cold",
        images: [
          {
            image: "grocery-channel/grocery_channel_cooler_banks_overlay.png",
            mixBlend: "multiply",
          },
          {
            image: "grocery-channel/grocery_channel_cooler_banks_text.png",
            mixBlend: "normal",
          },
        ],
        left: 887,
        top: 403,
        width: 243,
        height: 222,
        interaction: [
          { x: 888, y: 444 },
          { x: 1084, y: 444 },
          { x: 1090, y: 624 },
          { x: 888, y: 624 },
        ],
        info: "15% of Front-End shoppers purchase a beverage and 84% consume in less than an hour",
      },
    ],
  },
  {
    id: "convenience-gas-channel",
    map: "convenience-gas-channel/convenience_gas_channel_map.jpg",
    sectionHeaders: [
      { id: "cold-vault", text: "Cold Vault", color: "#94b0d4" },
      { id: "register", text: "Register", color: "#e7c9b1" },
      { id: "perimeter", text: "Perimeter", color: "#c3bacd" },
      { id: "food-service", text: "Food Service", color: "#cbd5b7" },
      { id: "secondary-cold", text: "Secondary Cold", color: "#dfdbad" },
      { id: "in-aisle", text: "In-Aisle", color: "#e0abd6" },
      { id: "outside", text: "Outside", color: "#b3d0d8" },
    ],
    sections: [
      {
        id: "in-aisle",
        name: "In Aisle",
        headerId: "in-aisle",
        images: [
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_in_aisle_overlay.png",
            mixBlend: "multiply",
          },
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_in_aisle_text.png",
            mixBlend: "normal",
          },
        ],
        left: 636,
        top: 268,
        width: 212,
        height: 208,
        interaction: [
          { x: 670, y: 269 },
          { x: 841, y: 269 },
          { x: 847, y: 474 },
          { x: 637, y: 474 },
        ],
        info: "Mtn Dew & Pepsi are two of the top 3 most noticed brands in C&G",
      },
      {
        id: "end-cap",
        name: "End Cap",
        headerId: "perimeter",
        images: [
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_end_cap_overlay.png",
            mixBlend: "multiply",
          },
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_end_cap_text.png",
            mixBlend: "normal",
          },
        ],
        left: 478,
        top: 325,
        width: 150,
        height: 151,
        interaction: [
          { x: 478, y: 325 },
          { x: 627, y: 325 },
          { x: 627, y: 474 },
          { x: 478, y: 474 },
        ],
      },
      {
        id: "perimeter",
        name: "Perimeter",
        headerId: "perimeter",
        images: [
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_perimeter_overlay.png",
            mixBlend: "multiply",
          },
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_perimeter_text.png",
            mixBlend: "normal",
          },
        ],
        left: 225,
        top: 480,
        width: 828,
        height: 151,
        interaction: [
          { x: 286, y: 481 },
          { x: 1031, y: 481 },
          { x: 1052, y: 630 },
          { x: 226, y: 630 },
        ],
        info: "80% of impulse purchases are driven by brand recognition",
      },
      {
        id: "secondary-cold-section",
        name: "Secondary Cold Section",
        headerId: "secondary-cold",
        images: [
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_secondary_cold_section_overlay.png",
            mixBlend: "multiply",
          },
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_secondary_cold_section_text.png",
            mixBlend: "normal",
          },
        ],
        left: 918,
        top: 272,
        width: 177,
        height: 202,
        interaction: [
          { x: 919, y: 275 },
          { x: 1050, y: 275 },
          { x: 1094, y: 472 },
          { x: 933, y: 472 },
        ],
        info: "Shoppers are 1.5x more likely to make an impulse purchase at open air cooler than other in-store destinations",
      },
      {
        id: "register-cold",
        name: "Register Cold",
        headerId: "register",
        images: [
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_register_cold_overlay.png",
            mixBlend: "multiply",
          },
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_register_cold_text.png",
            mixBlend: "normal",
          },
        ],
        left: 1070,
        top: 489,
        width: 423,
        height: 128,
        interaction: [
          { x: 1070, y: 490 },
          { x: 1432, y: 490 },
          { x: 1492, y: 615 },
          { x: 1095, y: 615 },
        ],
        info: "More than 13% of shoppers purchase in the front end because they want to try something new",
      },
      {
        id: "register-warm",
        name: "Register Warm",
        headerId: "register",
        images: [
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_register_warm_overlay.png",
            mixBlend: "multiply",
          },
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_register_warm_text.png",
            mixBlend: "normal",
          },
        ],
        left: 1095,
        top: 386,
        width: 335,
        height: 99,
        interaction: [
          { x: 1095, y: 386 },
          { x: 1384, y: 386 },
          { x: 1430, y: 483 },
          { x: 1115, y: 483 },
        ],
        info: "Shoppers in C-Stores are 58% more attracted to branded displays",
      },
      {
        id: "cold-vault",
        name: "Cold Vault",
        headerId: "cold-vault",
        images: [
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_cold_vault_overlay.png",
            mixBlend: "multiply",
          },
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_cold_vault_text.png",
            mixBlend: "normal",
          },
        ],
        left: 367,
        top: 107,
        width: 431,
        height: 154,
        interaction: [
          { x: 367, y: 108 },
          { x: 797, y: 108 },
          { x: 797, y: 261 },
          { x: 380, y: 261 },
        ],
        info: "30% who enter the store say they were influenced by signage",
      },
      {
        id: "food-service",
        name: "Food Service",
        headerId: "food-service",
        images: [
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_food_service_overlay_1.png",
            mixBlend: "normal",
          },
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_food_service_overlay_2.png",
            mixBlend: "multiply",
          },
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_food_service_text.png",
            mixBlend: "normal",
          },
        ],
        left: 953,
        top: 99,
        width: 327,
        height: 110,
        interaction: [
          { x: 956, y: 100 },
          { x: 1277, y: 100 },
          { x: 1277, y: 206 },
          { x: 956, y: 206 },
        ],
        info: "One third of Hot Food shoppers cart an additional category as they look for complementary item to create a full meal- most often salty snacks or beverage\n Source: Nailbiter PepsiCo Small Format Report 5.24.24",
      },
      {
        id: "coffee-station",
        name: "Coffee Station",
        headerId: "food-service",
        images: [
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_coffee_station_overlay.png",
            mixBlend: "multiply",
          },
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_coffee_station_text.png",
            mixBlend: "normal",
          },
        ],
        left: 1280,
        top: 132,
        width: 128,
        height: 179,
        interaction: [
          { x: 1283, y: 208 },
          { x: 1333, y: 135 },
          { x: 1408, y: 248 },
          { x: 1403, y: 309 },
          { x: 1330, y: 309 },
        ],
        info: "Starbucks has 64% Category Share in Convivence & Gas",
      },
      {
        id: "outside",
        name: "Outside",
        headerId: "outside",
        images: [
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_outside_overlay.png",
            mixBlend: "multiply",
          },
          {
            image:
              "convenience-gas-channel/convenience_gas_channel_outside_text.png",
            mixBlend: "normal",
          },
        ],
        left: 379,
        top: 651,
        width: 962,
        height: 76,
        interaction: [
          { x: 379, y: 651 },
          { x: 1341, y: 651 },
          { x: 1341, y: 727 },
          { x: 379, y: 727 },
        ],
        info: "One in four shoppers decide to enter the store after stopping for gas. Among those who go inside, 1/3 site marketing as their reason for entering\n Source: Nailbiter PepsiCo Small Format",
      },
    ],
  },
];
